<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('roles.search-by')" v-model="search_filter"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf blue" v-if="$auth.user().rights.admins_crud" @click="$router.push({name: 'vacant-jobs-create'})">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t('vacant_jobs.btn-new-vacant-job') }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals" v-if="filteredVacantJobs.length">
				<div class="row-space-tbf header-count-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="text-list-length">
							{{ $t('general.list_length') }} <span class="number">{{ filteredVacantJobs.length }}</span>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name">
							<div class="text">{{ $t('general.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-roles">
							<div class="text">{{ $t('general.roles') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-departments">
							<div class="text">{{ $t('profile.departments') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-workstation">
							<div class="text">{{ $t('create-user.workstation') }}</div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="vacantJob in filteredVacantJobs">
					<div class="row-space-tbf row-list-item" :key="vacantJob.id">
						<div class="space-left"><icon-vacant-job v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-name link" @click="showVacantJob(vacantJob.slug)">
								<div class="text">{{ vacantJob.name }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="vacantJob.roles.length == 1">{{ vacantJob.roles[0] }}</span>
										<span v-else-if="vacantJob.roles.length > 1">{{`${vacantJob.roles[0]} (+${(vacantJob.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ vacantJob.roles.join(", ") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-departments">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text departments">
										<span class="first" v-if="vacantJob.departments.length == 1">{{ vacantJob.departments[0] }}</span>
										<span v-else-if="vacantJob.departments.length > 1">{{`${vacantJob.departments[0]} (+${(vacantJob.departments.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ vacantJob.departments.join(", ") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1020)" class="column-simple-text column-workstation">
								<div class="text">{{ vacantJob.workstation ? vacantJob.workstation.name : '' }}</div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+vacantJob.id" :id="'dropdownEdit'+vacantJob.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ vacantJob.id " :aria-labelledby="'dropdownEdit'+vacantJob.id">
										<div class="dropdown-item" @click="showVacantJob(vacantJob.slug)"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="editVacantJob(vacantJob.slug)"><div class="simple-text">{{$t('general.edit')}}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'vacantJob', from: 'index_vacant_jobs', model: vacantJob})"><div class="simple-text">{{$t('general.delete')}}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+vacantJob.id" :id="'dropdownEdit'+vacantJob.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ vacantJob.id " :aria-labelledby="'dropdownEdit'+vacantJob.id">
									<div class="dropdown-item" @click="showVacantJob(vacantJob.slug)"><div class="simple-text">{{$t('general.view')}}</div></div>
									<div class="dropdown-item" @click="editVacantJob(vacantJob.slug)"><div class="simple-text">{{$t('general.edit')}}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'vacantJob', from: 'index_vacant_jobs', model: vacantJob})"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.vacant_jobs_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'vacant-jobs-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('vacant_jobs.create-new-vacant-job') }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
	import IconVacantJob from '../../Icons/VacantJob'
    import IconEdit from '../../Icons/EditDots'
	import IconEditMobile from '../../Icons/Edit'
	import IconClose from '../../Icons/Close'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'

    export default {
    	components: {
            IconSearch,
            IconPlus,
            IconArrow,
            IconVacantJob,
            IconEdit,
			IconEditMobile,
			IconClose,
           	LoaderItemsList, 
			LoaderHeaderList
        },
        data() {
            return {
            	loaded: true,
				loadedList: false,
            	search_filter: '',
            	vacantJobs: []
            }
        },
        async mounted(){
        	if(!this.$auth.user().rights.admins_instance_crud){
        		this.$router.push({name: 'forbbiden'})
        	}

        	await this.getVacantJobs()

			setTimeout(() => {
				var title = this.$t('vacant_jobs.title');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshVacantJobsIndex', () => {
				this.getVacantJobs()
			})
        },
		computed: {
			filteredVacantJobs(){
				return getByKeywordFilter(this.vacantJobs, this.search_filter)
			}
        },
        methods: {
        	async getVacantJobs(){
        		await axios.get(this.$auth.user().instance.id + '/vacant-jobs')
        		.then(({data}) => {
        			this.vacantJobs = data.data
        		}).catch(error => {
        			if(error.response) {
        				if(error.response.status == 500) {
        					alert(this.$t('error.500'))
        				}
        			}
        		})
        		.finally(() => {
        			this.loadedList = true
        			setTimeout(() => {
        				$('.opacity-page').addClass('show')
        			}, 0)
        		})
        	},
			showVacantJob(slug){
				this.$router.push({ name: 'vacant-jobs-show', params: {slug: slug} })
			},
			editVacantJob(slug){
				this.$router.push({ name: 'vacant-jobs-edit', params: {slug: slug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss" scoped>
	.list-goals{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.text{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
		}
		.column-roles{
			flex: 0 0 200px;
		}
		.column-departments{
			flex: 0 0 200px;
		}
		.column-workstation{
			flex: 0 0 200px;
		}
	}
	.dropdown-item {
		&.disabled{
			color: #AFB1B8 !important;
		}
	}
</style>